// src/imageReferences.js

import WhiskeyOldFashioned from '../../Media/cocktails/WhiskeyOldFashionedCocktail.jpg';
import TequilaPalomacoctail from '../../Media/cocktails/Tequila_Paloma_coctail.jpg'
import GrappaSour from '../../Media/cocktails/GrappaSour.jpg'
import GinBasilSmash from '../../Media/cocktails/GinBasilSmash.jpg'
import VishnyakCherryFizz from '../../Media/cocktails/VishnyakCherryFizz.jpg'
import ApplecelloSpritz from '../../Media/cocktails/ApplecelloSpritz.jpg'
import GrappaNegroni from '../../Media/cocktails/GrappaNegroni.jpg'
import WhiskeySour1 from '../../Media/cocktails/WhiskeySour1.jpg'
import GinFizz from '../../Media/cocktails/GinFizz.jpg'
import TequilaSunrise from '../../Media/cocktails/TequilaSunrise.jpg'
import VishnyakMule from '../../Media/cocktails/VishnyakMule.jpg'
import ApplecelloSidecar from '../../Media/cocktails/ApplecelloSidecar.jpg'
import GrappaManhattan from '../../Media/cocktails/GrappaManhattan.jpg'
import WhiskeySmash from '../../Media/cocktails/WhiskeySmash.jpg'
import GinRickey from '../../Media/cocktails/GinRickey.jpg'
import TequilaOldFashioned from '../../Media/cocktails/TequilaOldFashioned.jpg'
import VishnyakSour from '../../Media/cocktails/VishnyakSour.jpg'
import AppelcelloMojito from '../../Media/cocktails/AppelcelloMojito.jpg'
import GrappaEspressoMartini from '../../Media/cocktails/GrappaEspressoMartini.jpg'
import WhiskeyBoulevardier from '../../Media/cocktails/WhiskeyBoulevardier.jpg'


const imageReferences = {
    'WhiskeyOldFashionedCocktail.jpg': WhiskeyOldFashioned,
    'Tequila_Paloma_coctail.jpg' : TequilaPalomacoctail,
    'GrappaSour.jpg':GrappaSour,
    'GinBasilSmash.jpg':GinBasilSmash,
    'VishnyakCherryFizz.jpg':VishnyakCherryFizz,
    'ApplecelloSpritz.jpg':ApplecelloSpritz,
    'GrappaNegroni.jpg':GrappaNegroni,
    'WhiskeySour1.jpg':WhiskeySour1,
    'GinFizz.jpg':GinFizz,
    'TequilaSunrise.jpg':TequilaSunrise,
    'VishnyakMule.jpg' : VishnyakMule,
    'ApplecelloSidecar.jpg':ApplecelloSidecar,
    'GrappaManhattan.jpg' : GrappaManhattan,
    'WhiskeySmash.jpg':WhiskeySmash,
    'GinRickey.jpg':GinRickey,
    'TequilaOldFashioned.jpg':TequilaOldFashioned,
    'VishnyakSour.jpg':VishnyakSour,
    'AppelcelloMojito.jpg':AppelcelloMojito,
    'GrappaEspressoMartini.jpg':GrappaEspressoMartini,
    'WhiskeyBoulevardier.jpg':WhiskeyBoulevardier,
    

};

export default imageReferences;