// src/pages/CocktailRecipe.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import cocktails from '../../data/cocktails.json';
import './CocktailRecipe.css';
import imageReferences from '../../pages/CocktailCatalogue/imageReferences';

const CocktailRecipe = () => {
  const { id } = useParams();
  const cocktail = cocktails.find(c => c.id === parseInt(id));

  if (!cocktail) return <div>Cocktail not found</div>;

  return (
    <div className="cocktail-recipe">
      <Link to="/cocktails" className="back-link">&larr; Back to Cocktails</Link>
      <h1>{cocktail.name}</h1>
      <div className="recipe-content">
        <div className="recipe-image">
           <img src={imageReferences[cocktail.image]} alt={cocktail.name} />
        </div>
        <div className="recipe-details">
          <p className="description">{cocktail.shortDescription}</p>
          <div className="spirit-tag">{cocktail.spirit}</div>
          <h2>Ingredients</h2>
          <ul>
            {cocktail.ingredients.map((ingredient, index) => (
              <li key={index}>{ingredient}</li>
            ))}
          </ul>
          <h2>Instructions</h2>
          <ol>
            {cocktail.instructions.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CocktailRecipe;
