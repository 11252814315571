// src/components/ErrorPage/ErrorPage.jsx
import React from 'react';

const ErrorPage = ({ navigate }) => (
  <div>
    <h1>Oops! Something went wrong.</h1>
    <p>We're sorry, but we couldn't load the page you requested.</p>
    <button onClick={() => navigate('/')}>Return to Home</button>
  </div>
);

export default ErrorPage;