import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import { Link } from 'react-router-dom';
import HeroSection from './components/HeroSection/HeroSection';
import DistilleryHeader from './components/DistilleryHeader/DistilleryHeader';
import AboutUs from './pages/AboutUs/AboutUs';
import OurProducts from './components/OurProducts/OurProducts';
import ProductDetail from './components/ProductDetail/ProductDetail'; // We'll 
import ProductsPage from './pages/ProductPage/ProductPage';
// import Gin  from './pages/Gin/Gin';

// import Whiskey from './pages/Whiskey/Whiskey';
// import Applecello from './pages/Applecello/Applecello';
// import Vishnyak from './pages/Vishnyak/Vishnyak';
// import Grappa from './pages/Grappa/Grappa';
// import Tequila from './pages/Tequila/Tequila';
import CocktailCatalogue from './pages/CocktailCatalogue/CocktailCatalogue';
import CocktailRecipe from './pages/CocktailRecipe/CocktailRecipe';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';
import AgeVerification from './components/AgeVerification/AgeVerification';
import ErrorBoundary from './components/ErrorPage/ErrorBoundary';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtNdyyyRelhJXDGsJytlzxLKxDCBzfyNQ",
  authDomain: "trachtenberg-b7975.firebaseapp.com",
  projectId: "trachtenberg-b7975",
  storageBucket: "trachtenberg-b7975.appspot.com",
  messagingSenderId: "487061475950",
  appId: "1:487061475950:web:3334ed81be60360459cc7f",
  measurementId: "G-R1R6DNTCF9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Create a Home component that includes DistilleryHeader and HeroSection
const Home = () => (
  <>
    <HeroSection />
    {/* Include OurProducts component within the Home component */}
    <OurProducts />
    <AboutUs/>
    <CocktailCatalogue/>
    <Footer/>
    {/* <Contact/> */}
  </>
);

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <div className="App">
          <AgeVerification />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/:name" element={<ProductDetail />} /> {/* Changed this line */}
            <Route path="/cocktails" element={<CocktailCatalogue />} />
            <Route path="/cocktail/:id" element={<CocktailRecipe />} />
            <Route path="/lottery" element={<div>Lottery Page</div>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<div>404 - Page Not Found</div>} />
          </Routes>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;