import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Trachtenberg Spirits</h3>
          <p>Handcrafted Premium Spirits</p>
        </div>
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>Email: info@trachtenbergspirits.com</p>
          <p>Phone: (+972) 54-4641859</p>
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul className="footer-links">
          <li><Link to="/about">About</Link></li>
          <li><Link to="/products">Products</Link></li>
          <li><Link to="/cocktails">Cocktails</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Trachtenberg Spirits. All rights reserved.</p>
        <p className="created-by">Created by <a href="https://codesuits.com/" target="_blank" rel="noopener noreferrer">CodeSuits</a></p>
      </div>
    </footer>
  );
};

export default Footer;