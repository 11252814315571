import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './MovingText.css';

const messages = [
  'Welcome to Trachtenberg Distillery',
  'Crafting Spirits with Tradition and Passion',
  'Discover Our Handcrafted Premium Spirits',
  'Experience the Finest Artisanal Spirits',
  'Family-Owned Distillery Since 1920',
  'Award-Winning Craftsmanship in Every Bottle',
  'Tours and Tastings Available Daily',
  'Proudly Using Locally Sourced Ingredients',
  'Explore Our Exclusive Limited Edition Spirits',
  'Sustainably Distilled with Care for the Environment',
  'Join Our Distillery Membership for Exclusive Benefits',
  'Handpicked Ingredients, Masterfully Distilled',
  'From Grain to Glass: Our Distilling Process',
];

const MovingText = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 8000); // Display each message for 4 seconds
    return () => clearTimeout(timeout);
  }, [index]);

  return (
    <div className="moving-text-container">
      <TransitionGroup>
        <CSSTransition key={index} timeout={6000} classNames="fade">
          <p className="moving-text">{messages[index]}</p>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default MovingText;
