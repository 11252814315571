import React from 'react';
import { Link } from 'react-router-dom';
import cocktails from '../../data/cocktails.json';
import './CocktailCatalogue.css';
import imageReferences from '../../pages/CocktailCatalogue/imageReferences';
import backgroundImage from '../../Media/cocktailsbackgroundblack.jpg';




const CocktailCatalogue = () => {
  return (
    <div className="cocktail-catalogue-container" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="cocktail-catalogue">
        <h1>Our Signature Cocktails</h1>
        <div className="cocktail-grid">
          {cocktails.map((cocktail) => (
            <Link to={`/cocktail/${cocktail.id}`} key={cocktail.id} className="cocktail-card">
              <img src={imageReferences[cocktail.image]} alt={cocktail.name} />
              <div className="cocktail-info">
                <h2>{cocktail.name}</h2>
                <p>{cocktail.shortDescription}</p>
                <span className="spirit-tag">{cocktail.spirit}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CocktailCatalogue;