import React from 'react';
import './OurProducts.css';
import { Link } from 'react-router-dom';
import productData from '../../data/ProductData';

const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/&/g, '-and-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-');
};

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <Link to={`/${slugify(product.name)}`}> {/* Changed this line */}
        <img src={product.image} alt={product.name} />
        <h3>{product.name}</h3>
      </Link>
      <div className="product-description">
        <p>{product.description}</p>
      </div>
    </div>
  );
};

const OurProducts = () => {
  return (
    <div className="our-products">
      {/* Back Button */}
      <div className="back-button">
        <Link to="/our-products" className="back-link">
          ← Back to Our Products
        </Link>
      </div>

      <div className="products-header">
        <h2>Discover our range of handcrafted spirits.</h2>
      </div>
      <div className="product-catalogue">
        {productData.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default OurProducts;
