import React, { useState, useEffect } from 'react';
import { FaInstagram, FaPhone, FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">Trachtenberg Distillery</Link>
        </div>
        <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/products" onClick={toggleMenu}>Products</Link>
          <Link to="/cocktails" onClick={toggleMenu}>Cocktails</Link>
          <Link to="/about" onClick={toggleMenu}>About</Link>
          <a 
            href="https://www.instagram.com/trachtenbergdistillery/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="instagram-link mobile-instagram"
            onClick={toggleMenu}
          >
            <FaInstagram className="instagram-icon" />
            Instagram
          </a>
        </div>
        <div className="navbar-contact">
          <span className="phone-number">
            <FaPhone className="phone-icon" />(+972) 54-4641859
          </span>
          <a href="https://www.instagram.com/trachtenbergdistillery/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="instagram-icon" />
          </a>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;