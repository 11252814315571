// src/pages/ProductsPage/ProductsPage.jsx
import React from 'react';
import OurProducts from '../../components/OurProducts/OurProducts';
import '../ProductPage/common-product.css'

const ProductsPage = () => {
  return (
    <div>
      <OurProducts />
    </div>
  );
};

export default ProductsPage;
