import img_1_1 from '../Media/ourProducts/Rum1.jpg';
import img_2_1 from '../Media/ourProducts/tequila1.jpg';
import img_3_1 from '../Media/ourProducts/ChocolateRum1.jpg';
import img_4_1 from '../Media/ourProducts/Whiskey2.jpg';
import img_5_1 from '../Media/ourProducts/Whiskey4.jpg';
import img_6_1 from '../Media/ourProducts/SmokedWhiskey.jpg';
import img_7_1 from '../Media/ourProducts/vishnyak1.jpg';
import img_8_1 from '../Media/ourProducts/Calvados1.jpg';
import img_9_1 from '../Media/ourProducts/applecello1.jpg';
import img_10_1 from '../Media/ourProducts//mezcal1.jpg';

const ProductData = [
  {
    id: 1,
    name: 'Rum',
    image: img_1_1,
    additionalImages: [img_1_1],
    description: 'Aged rum matured for 5 years in French oak barrels, rich and smooth.',
    tastingNotes: 'Sweet caramel and vanilla with oak undertones.',
    aroma: 'Hints of tropical fruit and oak.',
    taste: 'Full-bodied with warm spices and a hint of fruitiness.',
    finish: 'Smooth with lingering vanilla and oak.',
    proof: '48%',
    aging: '5 years',
    barrel: 'French oak',
  },
  {
    id: 2,
    name: 'Rum NEW MAKE',
    image: img_2_1,
    additionalImages: [img_2_1],
    description: 'Unaged rum with a potent and robust profile.',
    tastingNotes: 'Raw cane sugar and tropical fruit.',
    aroma: 'Fresh and slightly sweet.',
    taste: 'Strong and fiery with a hint of molasses.',
    finish: 'Sharp and intense.',
    proof: '63%',
    aging: 'None',
  },
  {
    id: 3,
    name: 'Chocolate Rum',
    image: img_3_1,
    additionalImages: [img_3_1],
    description: 'Delicious dark chocolate-infused rum with a rich flavor profile.',
    tastingNotes: 'Creamy chocolate with a subtle rum finish.',
    aroma: 'Intense dark chocolate with underlying sweet notes.',
    taste: 'Smooth and decadent with a perfect balance of chocolate and rum.',
    finish: 'Sweet with lingering chocolate and warmth.',
    proof: '38%',
    flavor: 'Dark chocolate',
  },
  {
    id: 4,
    name: 'Whiskey',
    image: img_4_1,
    additionalImages: [img_4_1],
    description: 'Rich whiskey aged for 6 years, ODG smoked, and with 35% PPN, aged in Scottish peat.',
    tastingNotes: 'Smoky and peaty with a bold character.',
    aroma: 'Smoky peat with hints of spice.',
    taste: 'Bold, with smoked oak and a touch of sweetness.',
    finish: 'Long and smoky with subtle spice notes.',
    proof: '61.5%',
    aging: '6 years',
    barrel: 'Scottish peat',
    smoke: '35% PPN',
  },
  {
    id: 5,
    name: 'Whiskey NEW MAKE',
    image: img_5_1,
    additionalImages: [img_5_1],
    description: 'Unaged whiskey with a raw and robust flavor.',
    tastingNotes: 'Grain-forward with hints of sweetness.',
    aroma: 'Fresh grains with a hint of spice.',
    taste: 'Strong and slightly sweet.',
    finish: 'Clean and fiery.',
    proof: '63.5%',
    aging: 'None',
  },
  {
    id: 6,
    name: 'Smoked Whiskey',
    image: img_6_1,
    additionalImages: [img_6_1],
    description: 'Aged whiskey with a deep smoky flavor, aged since 2021.',
    tastingNotes: 'Intense smoke with layers of oak and spice.',
    aroma: 'Heavy smoke and subtle spice.',
    taste: 'Rich smoky flavor with a touch of sweetness.',
    finish: 'Long and smoky with a hint of oak.',
    proof: '55%',
    aging: 'Aged since 2021',
  },
  {
    id: 7,
    name: 'Vishnyak',
    image: img_7_1,
    additionalImages: [img_7_1],
    description: 'Traditional cherry liqueur with a rich and sweet flavor.',
    tastingNotes: 'Sweet and tangy cherry.',
    aroma: 'Intense cherry with hints of almond.',
    taste: 'Smooth and rich with bold cherry flavors.',
    finish: 'Sweet with lingering cherry notes.',
    proof: '22%',
  },
  {
    id: 8,
    name: 'Calvados',
    image: img_8_1,
    additionalImages: [img_8_1],
    description: 'A fine apple brandy from Normandy with a fresh and fruity flavor.',
    tastingNotes: 'Crisp apple with oak and subtle spice.',
    aroma: 'Ripe apple with a hint of oak.',
    taste: 'Smooth with a balance of fruit and oak.',
    finish: 'Clean and refreshing with apple notes.',
    proof: 'Varies',
  },
  {
    id: 9,
    name: 'Applecello',
    image: img_9_1,
    additionalImages: [img_9_1],
    description: 'A unique apple liqueur made with crisp apples, sweet and tart.',
    tastingNotes: 'Bright apple with a sweet-tart balance.',
    aroma: 'Fresh apple with hints of citrus.',
    taste: 'Sweet and tangy with crisp apple notes.',
    finish: 'Refreshing with a tart kick.',
    proof: '20%',
  },
  {
    id: 10,
    name: 'Mezcal',
    image: img_10_1,
    additionalImages: [img_10_1],
    description: 'Aged mezcal with a smooth and smoky profile, aged in French oak for 3 years.',
    tastingNotes: 'Smoky with layers of oak and agave.',
    aroma: 'Earthy smoke with hints of citrus.',
    taste: 'Smooth and rich with a smoky finish.',
    finish: 'Long and smoky with subtle sweetness.',
    proof: '44%',
    aging: '3 years',
    barrel: 'French oak',
  },
];

export default ProductData;
