import React, { useState, useEffect } from 'react';
import './AgeVerification.css';

const AgeVerification = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasVerified = sessionStorage.getItem('ageVerified');
    if (!hasVerified) {
      setShowPopup(true);
    }
  }, []);

  const handleVerification = (isOver18) => {
    if (isOver18) {
      sessionStorage.setItem('ageVerified', 'true');
      setShowPopup(false);
    } else {
      window.location.href = 'https://www.google.com';
    }
  };

  if (!showPopup) return null;

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-popup">
        <h2>This website is restricted to people who are over 18 years old </h2>
        <p>Are you over 18 years old?</p>
        <div className="age-verification-buttons">
          <button onClick={() => handleVerification(true)}>Yes</button>
          <button onClick={() => handleVerification(false)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;