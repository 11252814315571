// src/components/ErrorPage/ErrorBoundary.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorPage from './ErrorPage';

class ErrorBoundaryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error:', error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage navigate={this.props.navigate} />;
    }

    return this.props.children;
  }
}

// Wrapper function to provide navigation and location to the class component
function ErrorBoundary(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <ErrorBoundaryClass {...props} navigate={navigate} location={location} />;
}

export default ErrorBoundary;