import React from 'react';
import './AboutUs.css';
import backgroundImage from '../../Media/aboutuspub.jpg'

const AboutUs = () => {
  return (
      <div className="about-us-container" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="about-us-content">
        <h1>About Trachtenberg Distillery</h1>
        
        <section className="about-section intro">
          <h2>Crafting Excellence in Every Drop</h2>
          <p>
            Nestled in the heart of Rishon LeZion, Trachtenberg Distillery is a small-batch, premium spirits producer 
            dedicated to the art of crafting exceptional liquors. Our passion for quality and tradition drives 
            everything we do, from selecting the finest ingredients to meticulously overseeing every step of the 
            distillation process.
          </p>
        </section>
        
        <div className="about-grid">
          <section className="about-section philosophy">
            <h2>Our Philosophy</h2>
            <p>
              At Trachtenberg, we believe that truly remarkable spirits can only come from the very best ingredients. 
              That's why we source only the highest quality grains, fruits, and botanicals for our products. Our 
              commitment to excellence means no compromises – ever.
            </p>
          </section>
          
          <section className="about-section process">
            <h2>Small Batch, Big Flavor</h2>
            <p>
              Our small-batch approach allows us to maintain strict quality control over every bottle we produce. 
              By focusing on limited quantities, we ensure that each spirit receives the time and attention it 
              deserves, resulting in complex, nuanced flavors that stand out in any cocktail or enjoyed neat.
            </p>
          </section>
          
          <section className="about-section products">
            <h2>A Spirit for Every Palate</h2>
            <p>
              From our smooth, crisp vodka to our richly flavored whiskey, every Trachtenberg product is a 
              testament to our craftmanship. We take pride in offering a diverse range of spirits, each 
              with its own unique character, but all sharing our commitment to uncompromising quality.
            </p>
          </section>
          
          <section className="about-section commitment">
            <h2>Committed to Sustainability</h2>
            <p>
              We believe in respecting not just our craft, but also our environment. Trachtenberg Distillery 
              is committed to sustainable practices, from sourcing local ingredients whenever possible to 
              implementing eco-friendly production methods.
            </p>
          </section>
        </div>
        
        <section className="about-section invitation">
          <h2>Experience the Trachtenberg Difference</h2>
          <p>
            We invite you to taste the difference that passionate craftsmanship and premium ingredients make. 
            Whether you're a seasoned connoisseur or new to the world of fine spirits, Trachtenberg Distillery 
            offers an experience that will delight your senses and elevate your appreciation of artisanal liquors.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;