// src/pages/Contact/Contact.js

import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Here you would typically send this data to your backend
    // For this example, we'll just log it and show a success message
    console.log({ name, phone, email });

    // In a real application, you'd send this data to your server
    // which would then email it to clarotybcointest@gmail.com

    // Simulating an API call
    await new Promise(resolve => setTimeout(resolve, 1000));

    setMessage('Thank you for your message. We will get back to you soon!');
    setName('');
    setPhone('');
    setEmail('');
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Send</button>
      </form>
      {message && <div className="success-message">{message}</div>}
    </div>
  );
};

export default Contact;