// src/components/ProductDetail.jsx
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import productData from '../../data/ProductData';
import './ProductDetail.css';

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
};

const ProductDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => slugify(item.name) === name);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!product) {
      navigate('/products');
    }
  }, [product, navigate]);

  if (!product) {
    return null;
  }

  return (
    <div className="product-detail" style={{ backgroundImage: `url(${product.backgroundImage})` }}>
      <h1 className="product-name">{product.name}</h1>
      <div className="product-detail-content">
        <div className="product-image-container">
          <img src={product.image} alt={product.name} className="product-image" />
        </div>
        <div className="product-info">
          <div className="tasting-notes">
            <h2>Tasting Notes</h2>
            <p>{product.tastingNotes}</p>
          </div>
          <div className="attributes">
            <div className="attribute">
              <h3>Aroma</h3>
              <p>{product.aroma}</p>
            </div>
            <div className="attribute">
              <h3>Taste</h3>
              <p>{product.taste}</p>
            </div>
            <div className="attribute">
              <h3>Finish</h3>
              <p>{product.finish}</p>
            </div>
            <div className="attribute">
              <h3>Proof</h3>
              <p>{product.proof}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
