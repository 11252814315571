import React from 'react';
import 'animate.css';
import './HeroSection.css';
import heroImage from '../../Media/backgroung4.jpg';
import MovingText from '../MovingText/MovingText';

const HeroSection = () => {
  return (
    <div className="hero-section">
       <MovingText />
      <img src={heroImage} alt="Trachtenberg spirits" className="hero-image" />
      <div className="hero-text">
        <h1>Trachtenberg</h1>
      
      {/* <p className="handwriting animate__animated animate__tada animate__infinite" */}
      <p className="handwriting "
     >
          Handcrafted Premium Spirits
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
